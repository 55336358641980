import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import "@cg-squad/sudoku-client/dist/index.css";
import "../../css/pages/sudoku/sudoku.scss";
import * as queryString from "query-string";
import { isBrowser } from "../../utils/articleUtil";
import { requireAuth, useAuth } from "../../util/auth";
import auth0 from "../../util/auth0";
// import ProxySudoku from './ProxySudoku';
import loadable from "@loadable/component";
import AppContext from '../../AppContext';
//...other imports

const Sudoku = loadable(() => import("@cg-squad/sudoku-client"), {
  resolveComponent: (mod) => mod.Sudoku,
});

const SudokuGame = (props) => {
  const auth = useAuth();
  const [token, setToken] = useState(null);
  const appContext = useContext(AppContext)

  const logout = async () => {
    auth.signout();
  };

  useEffect(() => {
    console.log("============>", "within useEffect");
    console.log(auth?.user);
    document.body.style.padding=0
    if (!auth?.user) {
      let redirectUrl = "/auth/signin?next=/games/sudoku";
      if (isBrowser()) {
        const params = queryString.parse(window.location.search);
        if (params.email && params.email !== "") {
          redirectUrl += `&email=params.email`;
        }
      }
      navigate(redirectUrl);
    } else {
      const accessToken = auth0.extended.getAccessToken();
      console.log("=======>accessToken", !!accessToken);
      setToken(`Bearer ${accessToken}`);
    }
  }, []);

  /*const appContext = useContext(AppContext)
  useEffect(() => {
    console.log("---------------------");
    console.log(appContext.user);
    console.log("---------------------");
  }, [appContext?.user])*/

  const onTournamentClick = (data) => {
    appContext.updateState("sudokuData",data)
    navigate("/games/sudoku/play", { replace: true });
  };

  return (
    <>
      {token ? (
        <Sudoku
          token={token}
          apiUrl={process.env.GATSBY_SUDOKU_SERVER_URL}
          onLogout={logout}
          onTournamentClick={onTournamentClick}
        />
      ) : (
        <div className={"w-full h-[90vh] flex justify-center items-center"}>
          <div className="lds-ellipsis w-full h-full">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default requireAuth(SudokuGame);
