import React from "react";
import Layout from "../../components/layout";
import SudokuGame from "../../components/sudoku/SudokuGame";
import Helmet from "react-helmet";

const SudokuPage = (props) => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <Helmet>
        <script src='/metapixel.js'/>
      </Helmet>
      <main
        className=""
        style={{ paddingTop: 0 }}
        role="main"
        data-datocms-noindex
      >
        <SudokuGame />
      </main>
    </Layout>
  );
};

export default SudokuPage;
